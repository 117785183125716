<template>
  <div>
    <input id="topSearch" type="text" class="form-control pl-2 shadow"
           :placeholder="$t('SearchQuiz')"
           v-on:click="searchModal">
  </div>

  <div id="searchResult" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content bg-dark">
        <div class="modal-header border-0">
          <input id="searchInput" type="text" class="form-control pl-2 shadow"
                 :placeholder="$t('SearchQuiz')"
                 @input="getSearchResult">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="quiz" class="row">
            <div v-for="quizzes in quiz" :key="quizzes.id" class="col-sm-12 col-md-6 col-lg-4 pb-4">
              <div class="card search-item p-0 mb-2 h-100">
                <a :href="'/quizzes/' + quizzes.id">
                  <img class="card-img-top" v-for="images in quizzes.images" :src="'/'+images.path.replace('public', 'storage')"
                       :alt="quizzes.name">
                </a>
                <div class="card-body">
                <p>
                  <a :href="'/quizzes/' + quizzes.id">
                    {{ quizzes.name }}
                  </a>
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      quiz: null
    }
  },
  methods: {
    resetSearch() {
      this.quiz = null;
    },
    getSearchResult() {
      axios.post('/api/search', {
        topSearch: true,
        query: document.getElementById("searchInput").value,
        locale: document.documentElement.lang
      }).then((response) => {
        if (response.data.data.quizzes[0]) {
          this.quiz = response.data.data.quizzes;
        } else {
          this.quiz = null; // Reset if no results found
        }
      }).catch(() => {
        this.quiz = null; // Reset if there is an error
      });
    },
    searchModal() {
      // Show search modal #searchResult
      // Sélectionnez l'élément du modal
      const myModal = document.getElementById('searchResult');
      const searchInput = document.getElementById('searchInput');
      const modalInstance = new bootstrap.Modal(myModal);

      modalInstance.show();
      searchInput.focus();
    }
  }
}
</script>