<template>
  <!-- Filters -->
  <div id="filters" class="row d-flex mb-4 p-4 justify-content-center">
    <div class="col-auto">
      <select name="difficultySelect" class="btn btn-primary mr-4 p-2" v-model="difficultySelect" v-on:change="filterDifficulty($event)">
        <option disabled selected value="0">{{ $t('Difficulty') }}</option>
        <option value="1">{{ $t("Easy") }}</option>
        <option value=2>{{ $t("Medium") }}</option>
        <option value=3>{{ $t("Hard") }}</option>
      </select>
    </div>
    <div v-if="Categories" class="col-auto">
      <select name="categorySelect" class="btn btn-primary mr-4 p-2" v-model="categorySelect" v-on:change="filterCategory($event)">
        <option disabled selected value="0">{{ $t('Theme') }}</option>
        <option v-for="category in Categories" :value="category.id">{{ category.name }}</option>
      </select>
    </div>
    <div v-if="this.playerId >= 1" class="col-auto">
      <button v-on:click="filterNeverPlayed" class="btn btn-primary mr-4" type="button">
        {{ $t("NeverPlayed") }}
      </button>
    </div>
    <div v-if='this.playerId === "null"' class="col-auto">
      <button data-bs-toggle="modal" data-bs-target="#neverPlayedModal" class="btn btn-primary mr-4" type="button">
        {{ $t("NeverPlayed") }}
      </button>
    </div>
    <div class="col-auto">
      <button v-on:click="filterNew" class="btn btn-primary mr-4" type="button">
        {{ $t("New") }}
      </button>
    </div>
    <div v-if="resetFiltersButton" class="col-auto">
      <button v-on:click="resetFilters" class="btn btn-dark mr-4" v-text="$t('ResetFilters')"></button>
    </div>
  </div>
  <!-- End filters -->
  <!-- Quiz Result -->
  <div v-if="showResult" id="quizResult" class="card">
    <div class="card-header">
      <span v-html="resultTitle"></span>
      <span v-on:click="hideResult" class="material-icons cursor-pointer float-end">close</span>
    </div>
    <div id="card-body">
      <div class="card-text p-4" v-html="resultText"></div>
      <a :href="resultSources" class="btn btn-primary m-4 float-right" v-text="$t('Sources')" target="_blank"></a>
    </div>
  </div>
  <!-- End quiz result -->
  <div v-bind:id="'quiz'+quizId" class="d-flex align-items-start quiz-item mb-4" v-if="quizId">
    <div class="row w-100 m-0">
      <div class="col-12 p-0 progress">
        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="col-lg-auto col-xl-auto col-md-4 col-sm-12 p-0">
        <img :src="'../../'+quizImages" onerror="this.onerror=null;this.src='/images/default.jpg';" class="img-fluid" :alt="quizName">
      </div>
      <div class="col-lg col-xl col-md-8 col-sm-12">
        <div class="pl-4 pt-4">
          <h3>{{ quizName }}</h3>
          <br>
          <span class="quizQuestion d-flex" v-html="quizAnswers.question"></span>
          <div class="row answersOptions mb-4 m-0">
            <div v-for="quizOption in quizOptions" class="col-md-6 col-lg-6 col-sm-12 mb-4">
              <span v-on:click="checkAnswer(quizId, quizOption.id)" class="quiz-option h-100">{{ quizOption.option }}</span>
            </div>
          </div><!-- answersOptions -->
        </div>
      </div>
      <div class="col-12">
        <!-- Credits -->
        <span class="mb-2 mt-2 d-block">
          <a :href="this.credits"
             v-text="$t('PhotoCredits') + this.credits_name"
             target="_blank"
             class="text-surface-600"
          ></a>
        </span>
        <!-- End credits -->
        <button v-on:click="copyLink" class="btn btn-primary mr-4" data-toggle="tooltip" :title="$t('CopyLink')">
          <span class="material-icons align-top">share</span>
        </button>
        <button data-toggle="modal" data-bs-target="#signalModal"
                class="btn btn-primary mr-4" :title="$t('SignalQuiz')">
          <span class="material-icons align-top">warning</span>
        </button>
        <button v-on:click="skipQuiz" class="btn btn-primary mr-4" data-toggle="tooltip" :title="$t('NextQuiz')">
          <span class="material-icons align-top">skip_next</span>
        </button>
        <span v-if="quizRsiLink" class="btn btn-primary mr-4">
            <a :href="quizRsiLink" target="_blank">
              <span class="material-icons align-top">insert_link</span>
              {{ $t("Source") }}
            </a>
          </span>
        <span v-if="quizMedia" class="btn btn-primary mr-4">
            <a :href="quizMedia" target="_blank">
              <span class="material-icons align-top">insert_link</span>
              {{ $t("MediaLink") }}
            </a>
          </span>
      </div>
      <!-- Tags -->
      <div v-if="showTags" class="col-12">
        <div id="tags" class="pb-4 text-center">
          <label>Tags : </label>
          <span class="m-2" v-for="tag in tags">
            <a v-for="item in tag" :href="/tags/+item.id" v-text="item.title"></a>
          </span>
        </div>
      </div>
      <!-- End tags -->
    </div>
  </div><!-- quiz -->
  <div v-else>
    <div class="d-flex align-items-center sq-dark-bg">
      <div class="flex-shrink-0 w-25">
        <img src="/images/default.jpg" class="card-img-top" :alt="$t('NoQuiz')">
      </div>
      <div class="flex-grow-1 ms-3 text-center">
        <p>{{ $t("NoQuiz") }}</p>
        <p v-if="fNew !== false || fNeverPlayed !== false || fDifficulty !== 0 || fCategory !== 0">
          <button v-on:click="this.resetFilters()" class="btn btn-primary">
            {{ $t("ResetFilters") }}
          </button>
        </p>
      </div>
    </div>
  </div>
  <!-- Report Modal -->
  <div class="modal" id="signalModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content sq-dark-bg">
        <div class="modal-header border-0">
          <button type="button" class="btn-close white-svg" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label for="signalQuizMessage" v-text="$t('SignalQuizLabel')"></label>
          <textarea id="signalQuizMessage" class="sq-dark-bg w-100 text-white p-4"></textarea>
        </div>
        <div class="modal-footer border-0">
          <button v-on:click="signalQuiz" type="button" class="btn btn-primary" v-text="$t('SignalQuiz')"></button>
        </div>
      </div>
    </div>
  </div>
  <!-- End report Modal -->
  <!-- Neverplayed Modal -->
  <div class="modal fade" id="neverPlayedModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content sq-dark-bg">
        <div class="modal-header border-0">
          <h3>Whoops...</h3>
          <button type="button" class="btn-close white-svg" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-text="$t('YouMustBeLoggedInTo')"></p>
        </div>
        <div class="modal-footer border-0">
          <a href="/login" type="button" class="btn btn-primary" v-text="$t('Login')"></a>
          <a href="/register" type="button" class="btn btn-primary" v-text="$t('Register')"></a>
        </div>
      </div>
    </div>
  </div>
  <!-- End Neverplayed Modal -->

</template>
<script>

import discord from "../../discord";
import {copyLinkToClipboard} from "../../custom";
import { trans } from 'laravel-vue-i18n';
export default {
  data() {
    return {
      quizId: {},
      quizName: {},
      quizAnswers: {},
      quizCategory: {},
      quizDifficulty: {},
      quizImages: {},
      credits: {},
      credits_name: {},
      quizOptions: {},
      quizRsiLink: "",
      quizMedia: "",
      quizTags: [],
      tags: [],
      showTags: false,
      showResult: null,
      resultTitle: null,
      resultText: null,
      resultSources: null,
      fNew: false,
      fNeverPlayed: false,
      fDifficulty: 0,
      resetFiltersButton: false,
      skipQuizId: [],
      playerId: 0,
      Categories: {},
      fCategory: 0,
      difficultySelect: 0,
      categorySelect: 0,
      lastQuizzes: []
    }
  },
  methods: {
    setQuizTags() {
      if (this.quizTags.length) {
        this.tags = [];
        this.quizTags.forEach(element => this.tags.push(element.tags));
        return this.showTags = true;
      }
      this.showTags = false;
    },
    setLastQuizzes() {
      if (this.lastQuizzes.includes(this.quizId)) {
        return;
      }
      if (this.lastQuizzes.length >= 20) {
        this.lastQuizzes = [];
      }
      this.lastQuizzes.push(this.quizId);
    },
    getLastQuizzes() {
      if (this.lastQuizzes.length) {
        return JSON.stringify(this.lastQuizzes);
      }
      return null;
    },
    getSkipQuizId() {
      if (this.skipQuizId.length) {
        return JSON.stringify(this.skipQuizId);
      }
      return null;
    },
    getAllCategories() {
      axios.get('/api/quizzes/categories').then((response) => {
        if (response.data.data.categories[0]) {
          this.Categories = response.data.data.categories;
        }
      });
    },
    getPlayerId() {
      this.playerId = $("meta[name='user-id']").attr("content");
    },
    hideResult() {
      this.showResult = false;
    },
    resetFilters() {
      this.resetFiltersButton = false;
      this.fNew = false;
      this.fNeverPlayed = false;
      this.fDifficulty = 0;
      this.difficultySelect = 0;
      this.fCategory = 0;
      this.categorySelect = 0;
      this.skipQuizId = [];
      this.lastQuizzes = [];
      this.getQuizzes();
    },
    filterCategory(state) {
      this.fCategory = state.target.value;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterDifficulty(state) {
      this.fDifficulty = state.target.value;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterNeverPlayed() {
      this.fNeverPlayed = this.fNeverPlayed !== true;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterNew() {
      this.fNew = this.fNew !== true;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    skipQuiz() {
      this.skipQuizId.push(this.quizId);
      this.resetFiltersButton = true;
      this.getQuizzes();
      // Si le nombre de skip atteind 5 on reset
      if (this.skipQuizId.length > 5) {
        this.skipQuizId = [];
        this.resetFiltersButton = false;
      }
    },
    copyLink() {
      copyLinkToClipboard(this.quizId);
    },
    signalQuiz() {
      let label = $("#signalQuizMessage").val();
      discord.sendMessage("Quiz report id : "+this.quizId+ " Message : " + label);
      $("#signalModal").modal("hide");
    },
    getQuizzes () {
      let urlQuizId = window.location.href.split('/').pop();

      if (!parseInt(urlQuizId)) {
        urlQuizId = 0;
      }

      axios.post('/api/quizzes/random', {
        quizId: parseInt(urlQuizId),
        playerId: parseInt(this.playerId),
        neverPlayed: this.fNeverPlayed,
        difficulty: this.fDifficulty,
        category: this.fCategory,
        new: this.fNew,
        skipQuiz: this.getSkipQuizId(),
        locale: $("html").attr("lang")
      }).then((response) => {
        if (response.data.data.quizzes[0]) {
          let items = response.data.data.quizzes[0];
          this.quizId = items.id;
          this.quizName = items.name;
          this.quizAnswers = items.answers[0];
          this.quizCategory = items.category;
          this.quizDifficulty = items.difficulty[0];
          this.quizImages = items.images[0].path.replace('public', 'storage');
          this.credits = items.images[0].credits;
          this.credits_name = items.images[0].credits_name;
          this.quizOptions = items.quiz_options;
          this.quizRsiLink = items.answers[0].rsi_link;
          this.quizMedia = items.answers[0].media_link;
          this.quizTags = items.tag;
          this.setDifficulty(items.difficulty[0].id);
          this.showResult = false;
          this.setLastQuizzes();
          this.setQuizTags();
        } else {
          this.quizId = null;
        }
      });
    },
    setDifficulty: function (difficultyId) {
      $("div.progress-bar, span.difficultyLevelList").removeClass(["bg-success-stripped", "bg-warning-stripped", "bg-danger-stripped"])
      if (difficultyId === 1) {
        $("div.progress-bar, span.difficultyLevelList").addClass("bg-success-stripped");
      } else if (difficultyId === 2) {
        $("div.progress-bar, span.difficultyLevelList").addClass("bg-warning-stripped");
      } else {
        $("div.progress-bar, span.difficultyLevelList").addClass("bg-danger-stripped");
      }
    },
    checkAnswer: function (quizId, optionId) {
      let ref = this;
      axios.post('/api/quiz/result', {
          quizId: quizId,
          optionId: optionId,
          playerId: parseInt(this.playerId),
          neverPlayed: this.fNeverPlayed,
          difficulty: this.fDifficulty,
          category: this.fCategory,
          new: this.fNew,
          lastQuizzes: this.getLastQuizzes(),
          skipQuiz: this.getSkipQuizId(),
          locale: $("html").attr("lang")
        })
          .then((response) => {
            if (response.data.data.quiz[0]) {
              let items = response.data.data.quiz[0];
              ref.quizId = items.id;
              ref.quizName = items.name;
              ref.quizAnswers = items.answers[0];
              ref.quizCategory = items.category;
              ref.quizDifficulty = items.difficulty[0];
              if (items.images[0]) {
                ref.quizImages = items.images[0].path.replace('public', 'storage');
                ref.credits = items.images[0].credits;
                ref.credits_name = items.images[0].credits_name;
              } else {
                ref.quizImages = "/images/default.jpg";
              }
              ref.quizOptions = items.quiz_options;
              ref.quizRsiLink = items.answers[0].rsi_link;
              ref.quizMedia = items.answers[0].media_link;
              ref.quizTags = items.tag;
              ref.setQuizTags();
              ref.setDifficulty(items.difficulty[0].id);
              ref.showResult = true;
              ref.setQuizResult(response.data);
              this.setLastQuizzes();
            } else {
              this.quizId = null;
            }
          });
    },
    setQuizResult(data) {
      let success = data.data.success;
      let items = data.data;
      this.showResult = true;

      if (success === true) {
        this.resultTitle = trans("WellDone");
      } else {
        this.resultTitle = "Oups...";
      }
      this.resultText = items.explication;
      this.resultSources = items.sources;
      document.getElementById('filters').scrollIntoView();
    },
  },
  created() {
    this.getPlayerId();
    this.getQuizzes();
    this.getAllCategories();
  },
}
</script>