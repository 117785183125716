<template>
  <!-- Filters -->
  <div id="filters" class="row d-flex mb-4 p-4 justify-content-center">
    <div class="col-auto">
      <select name="difficultySelect" class="btn btn-primary mr-4 p-2" v-model="difficultySelect" v-on:change="filterDifficulty($event)">
        <option disabled selected value="0">{{ $t('Difficulty') }}</option>
        <option value="1">{{ $t("Easy") }}</option>
        <option value=2>{{ $t("Medium") }}</option>
        <option value=3>{{ $t("Hard") }}</option>
      </select>
    </div>
    <div v-if="Categories" class="col-auto">
      <select name="categorySelect" class="btn btn-primary mr-4 p-2" v-model="categorySelect" v-on:change="filterCategory($event)">
        <option disabled selected value="0">{{ $t('Theme') }}</option>
        <option v-for="category in Categories" :value="category.id">{{ category.name }}</option>
      </select>
    </div>
    <div v-if="this.playerId >= 1" class="col-auto">
      <button v-on:click="filterNeverPlayed" class="btn btn-primary mr-4" type="button">
        {{ $t("NeverPlayed") }}
      </button>
    </div>
    <div v-if='this.playerId === "null"' class="col-auto">
      <button data-bs-toggle="modal" data-bs-target="#neverPlayedModal" class="btn btn-primary mr-4" type="button">
        {{ $t("NeverPlayed") }}
      </button>
    </div>
    <div class="col-auto">
      <button v-on:click="filterNew" class="btn btn-primary mr-4" type="button">
        {{ $t("New") }}
      </button>
    </div>
    <div v-if="resetFiltersButton" class="col-auto">
      <button v-on:click="resetFilters" class="btn btn-dark mr-4" v-text="$t('ResetFilters')"></button>
    </div>
  </div>
  <!-- End filters -->

  <div class="row m-0">
    <div v-if="lastQuizzes" v-for="lastQuiz in lastQuizzes" class="col-md-4 col-lg-3 col-sm-6 col-xs-6 v-100 mb-4">
      <div class="card shadow">
        <div class="card__overlay sq-dark-bg">
          <div class="overlay__text">
            <span class="d-block mb-4">{{ this.getSqSubStr(lastQuiz.name) }}</span>
            <a :href="'quizzes/'+lastQuiz.id" class="btn btn-primary mt-4">
              {{ $t('Play') }}
            </a>
          </div>
        </div>

        <!-- Image -->
        <a v-for="quizImage in lastQuiz.images" :href="'quizzes/'+lastQuiz.id">
          <img :src="getQuizImages(quizImage.path)"
               onerror="this.onerror=null;this.src='/images/default.jpg';"
               class="img-fluid w-100" :alt="lastQuiz.name">
        </a>
        <!-- End image -->

        <!-- Difficulty -->
        <div v-if="lastQuiz.difficulty_id === 1" class="progress">
          <div class="progress-bar bg-success-stripped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span v-if="lastQuiz.difficulty_id === 1" class="difficultyLevel text-uppercase">
          {{ $t('Easy') }}
        </span>
        <div v-if="lastQuiz.difficulty_id === 2" class="progress">
          <div class="progress-bar bg-warning-stripped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span v-if="lastQuiz.difficulty_id === 2" class="difficultyLevel">
          {{ $t('Medium') }}
        </span>
        <div v-if="lastQuiz.difficulty_id === 3" class="progress">
          <div class="progress-bar bg-danger-stripped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span v-if="lastQuiz.difficulty_id === 3" class="difficultyLevel">
          {{ $t('Hard') }}
        </span>
        <!-- End difficulty -->

        <div class="card-body">
          <p>
            <a :href="'quizzes/'+lastQuiz.id" data-toggle="tooltip" :title="lastQuiz.name">
              {{ this.getSqSubStr(lastQuiz.name) }}
            </a>
          </p>
        </div>
      </div><!-- card -->
    </div><!-- flex-fill -->
    <!-- No Quiz -->
    <div v-else class="d-flex align-items-center sq-dark-bg w-100 mb-4">
      <div class="flex-shrink-0 w-25">
        <img src="/images/default.jpg" class="card-img-top" alt="Aucun quiz">
      </div>
      <div class="flex-grow-1 ms-3 text-center">
        {{ $t('NoQuiz') }}
      </div>
    </div>
    <!-- End no quiz -->
    <div v-if="lastQuizzes && showLoadMore" class="col-12 d-flex">
      <button v-on:click="infiniteHandler" class="btn btn-primary mx-auto mb-4 m-4">
        {{ $t("LoadMore") }}
      </button>
    </div>
  </div>
  <!-- Neverplayed Modal -->
  <div class="modal" id="neverPlayedModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content sq-dark-bg">
        <div class="modal-header border-0">
          <h3>Whoops...</h3>
          <button type="button" class="btn-close white-svg" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-text="$t('YouMustBeLoggedInTo')"></p>
        </div>
        <div class="modal-footer border-0">
          <a href="/login" type="button" class="btn btn-primary" v-text="$t('Login')"></a>
          <a href="/register" type="button" class="btn btn-primary" v-text="$t('Register')"></a>
        </div>
      </div>
    </div>
  </div>
  <!-- End Neverplayed Modal -->
</template>
<script>

import { sqSubStr } from "../../custom";
export default {
  data() {
    return {
      lastQuizzes: {},
      fNew: false,
      fNeverPlayed: false,
      fDifficulty: 0,
      resetFiltersButton: false,
      playerId: 0,
      page: 2,
      showLoadMore: false,
      Categories: {},
      fCategory: 0,
      difficultySelect: 0,
      categorySelect: 0,
    }
  },
  methods: {
    getSqSubStr(string) {
      return sqSubStr(string);
    },
    getAllCategories() {
      axios.get('/api/quizzes/categories').then((response) => {
        if (response.data.data.categories[0]) {
          this.Categories = response.data.data.categories;
        }
      });
    },
    infiniteHandler() {
      axios.post('/api/quizzes/home?page='+this.page, {
        playerId: this.playerId,
        neverPlayed: this.fNeverPlayed,
        difficulty: this.fDifficulty,
        skipQuizId: this.skipQuizId,
        new: this.fNew,
        locale: $("html").attr("lang")
      }).then((response) => {
        if (response.data.data.quizzes.data[0]) {
          this.lastQuizzes.push(...response.data.data.quizzes.data);
        } else {
          this.showLoadMore = false;
        }
        if (this.page === response.data.data.quizzes.last_page) {
          this.showLoadMore = false;
        }
        this.page = this.page + 1;
      });
    },
    getQuizzes() {
      axios.post('/api/quizzes/home', {
        playerId: this.playerId,
        neverPlayed: this.fNeverPlayed,
        difficulty: this.fDifficulty,
        skipQuizId: this.skipQuizId,
        category: this.fCategory,
        new: this.fNew,
        locale: $("html").attr("lang")
      }).then((response) => {
        if (response.data.data.quizzes.data[0]) {
          this.lastQuizzes = response.data.data.quizzes.data;
          this.setLoadMoreButton(response.data.data.quizzes.total);
        } else {
          this.lastQuizzes = null;
        }
      });
    },
    setLoadMoreButton(total) {
      if (total >= 13) {
        return this.showLoadMore = true;
      }
      this.showLoadMore = false;
    },
    getQuizImages(path) {
      return path.replace('public', 'storage');
    },
    getPlayerId() {
      this.playerId = $("meta[name='user-id']").attr("content");
    },
    resetFilters() {
      this.resetFiltersButton = false;
      this.fNew = false;
      this.fNeverPlayed = false;
      this.fDifficulty = 0;
      this.difficultySelect = 0;
      this.fCategory = 0;
      this.categorySelect = 0;
      this.getQuizzes();
    },
    filterCategory(state) {
      this.fCategory = state.target.value;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterDifficulty(state) {
      this.fDifficulty = state.target.value;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterNeverPlayed() {
      this.fNeverPlayed = this.fNeverPlayed !== true;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterNew() {
      this.fNew = this.fNew !== true;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
  },
  created() {
    this.getPlayerId();
    this.getQuizzes();
    this.getAllCategories();
  },
}
</script>