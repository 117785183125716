<template>
  <!-- Filters -->
  <div v-if="!quizUrl" id="filters" class="align-items-start sq-dark-bg mb-4 p-4">
    <span class="align-top my-auto mr-4">{{ $t("Filters") }}</span>
    <select name="difficultySelect" class="btn btn-primary mr-4 p-2" v-model="difficultySelect" v-on:change="filterDifficulty($event)">
      <option disabled selected value="0">{{ $t('Difficulty') }}</option>
      <option value="1">{{ $t("Easy") }}</option>
      <option value=2>{{ $t("Medium") }}</option>
      <option value=3>{{ $t("Hard") }}</option>
    </select>
    <select v-if="Categories" name="categorySelect" class="btn btn-primary mr-4 p-2" v-model="categorySelect" v-on:change="filterCategory($event)">
      <option disabled selected value="0">{{ $t('Theme') }}</option>
      <option v-for="category in Categories" :value="category.id">{{ category.name }}</option>
    </select>
    <button v-on:click="filterNeverPlayed" class="btn btn-primary mr-4" type="button">
      {{ $t("NeverPlayed") }}
    </button>
    <button v-on:click="filterNew" class="btn btn-primary mr-4" type="button">
      {{ $t("New") }}
    </button>
    <button v-if="resetFiltersButton" v-on:click="resetFilters" class="btn btn-dark mr-4" v-text="$t('ResetFilters')"></button>
  </div>
  <!-- End filters -->
  <!-- input -->
  <div v-if="!quizUrl" class="d-flex align-items-start sq-dark-bg mb-4 w-100">
    <div class="row w-100 m-0">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-4">
        <input type="text" class="form-control" name="templateName" :placeholder="$t('QuizName')">
        <p class="pt-4">
          {{ $t("TemplateTotalQuestions") }} <span v-text="totalAddedToQuiz"></span>
        </p>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-4 text-right">
        <div class="form-group">
            <p v-on:click="setQuizPublic">
              <input type="checkbox" name="publicQuiz">
              {{ $t("TemplateCheckboxDisclaimer") }}
            </p>
        </div>
        <button v-if="addedToQuiz.length" v-on:click="saveCustomQuiz()" class="btn btn-primary" v-text="$t('Save')"></button>
      </div>
    </div>
  </div>
  <!-- end input -->
  <div v-if="!quizUrl" class="row m-0">
    <div v-if="lastQuizzes" v-for="(lastQuiz, index) in lastQuizzes" :id="'quiz'+lastQuiz.id" class="col-md-4 col-lg-2 col-sm-6 col-xs-6 v-100 mb-4">
      <div class="card shadow"  v-on:click="setAddToQuiz(lastQuiz.id, index)">
        <div class="card__overlay sq-dark-bg">
          <div class="overlay__text">
            <span class="d-block mb-4">{{ $t('Theme') }} {{ lastQuiz.name }}</span>
            <a class="btn btn-primary mt-4">
              {{ $t('AddToQuiz') }}
            </a>
          </div>
        </div>

        <!-- Image -->
        <a v-for="quizImage in lastQuiz.images">
          <img :src="'/'+getQuizImages(quizImage.path)"
               onerror="this.onerror=null;this.src='/images/default.jpg';"
               class="card-img-top" :alt="lastQuiz.name">
        </a>
        <!-- End image -->

        <!-- Difficulty -->
        <div v-if="lastQuiz.difficulty_id === 1" class="progress">
          <div class="progress-bar bg-success-stripped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span v-if="lastQuiz.difficulty_id === 1" class="difficultyLevel text-uppercase">
          {{ $t('Easy') }}
        </span>
        <div v-if="lastQuiz.difficulty_id === 2" class="progress">
          <div class="progress-bar bg-warning-stripped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span v-if="lastQuiz.difficulty_id === 2" class="difficultyLevel">
          {{ $t('Medium') }}
        </span>
        <div v-if="lastQuiz.difficulty_id === 3" class="progress">
          <div class="progress-bar bg-danger-stripped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span v-if="lastQuiz.difficulty_id === 3" class="difficultyLevel">
          {{ $t('Hard') }}
        </span>
        <!-- End difficulty -->

        <div class="card-body">
          <p class="card-text">
            {{ lastQuiz.name }}
          </p>
        </div>
      </div><!-- card -->
    </div><!-- flex-fill -->
    <!-- No Quiz -->
    <div v-else class="d-flex align-items-center sq-dark-bg w-100">
      <div class="flex-shrink-0 w-25">
        <img src="/images/default.jpg" class="card-img-top" alt="Aucun quiz">
      </div>
      <div class="flex-grow-1 ms-3 text-center">
        {{ $t('NoQuiz') }}
      </div>
    </div>
    <!-- End no quiz -->
    <div v-if="lastQuizzes && showLoadMore" class="col-12 d-flex">
      <button v-on:click="infiniteHandler" class="btn btn-primary mx-auto mb-4 m-4">
        {{ $t("LoadMore") }}
      </button>
    </div>
  </div>
  <div v-if="quizUrl" class="row">
    <div class="col-12 sq-dark-bg p-4">
      <span class="w-100 d-block text-center mb-4">{{ $t("ConfirmAddCustomQuiz") }}</span>
      <div class="justify-content-end d-flex">
        <a class="btn btn-primary m-4" :href="quizUrl" v-text="$t('Show')" data-link="quizUrl"></a>
        <button v-on:click="copyLink()" class="btn btn-primary m-4" v-text="$t('CopyLink')" data-link="quizUrl"></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lastQuizzes: {},
      fNew: false,
      fNeverPlayed: false,
      fDifficulty: 0,
      resetFiltersButton: false,
      playerId: 0,
      page: 2,
      showLoadMore: false,
      Categories: {},
      fCategory: 0,
      difficultySelect: 0,
      categorySelect: 0,
      addedToQuiz: [],
      totalAddedToQuiz: 0,
      publicQuizValue: 0,
      successMessage: false,
      errorMessage: false,
      quizUrl: null,
    }
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText($('.customQuizLink').attr('data-link')).then(
          function() {
            /* clipboard successfully set */
            window.alert('Success! The text was copied to your clipboard')
          },
          function() {
            /* clipboard write failed */
            window.alert('Opps! Your browser does not support the Clipboard API')
          }
      )
    },
    setQuizPublic() {
      let element = $("input[name='publicQuiz']");
      if (element.is(":checked")) {
        this.publicQuizValue = 0;
        return element.attr("checked", false);
      }
      element.attr("checked", true);
      this.publicQuizValue = 1;
    },
    setCardSelected(quizId) {
      $("#quiz"+quizId).css("opacity", "0.5");
      this.totalAddedToQuiz = this.totalAddedToQuiz+1;
    },
    deleteCardSelected(quizId) {
      $("#quiz"+quizId).css("opacity", "1");
      this.totalAddedToQuiz = this.totalAddedToQuiz-1;
    },
    // Add quiz to the selectd list of quiz
    setAddToQuiz(quizId, index) {
      if (this.addedToQuiz.indexOf(quizId) > -1) {
        this.deleteCardSelected(quizId);
        return this.addedToQuiz.splice(this.addedToQuiz.indexOf(quizId), 1);
      }
      this.addedToQuiz.push(quizId);
      this.setCardSelected(quizId);
    },
    getAllCategories() {
      axios.get('/api/quizzes/categories').then((response) => {
        if (response.data.data.categories[0]) {
          this.Categories = response.data.data.categories;
        }
      });
    },
    infiniteHandler() {
      axios.post('/api/quizzes/home?page='+this.page, {
        playerId: this.playerId,
        neverPlayed: this.fNeverPlayed,
        difficulty: this.fDifficulty,
        skipQuizId: this.skipQuizId,
        new: this.fNew,
        locale: $("html").attr("lang")
      }).then((response) => {
        if (response.data.data.quizzes.data[0]) {
          this.lastQuizzes = response.data.data.quizzes.data;
        } else {
          this.showLoadMore = false;
        }
      });
      this.page = this.page + 1;
    },
    getQuizzes() {
      axios.post('/api/quizzes/home', {
        playerId: this.playerId,
        neverPlayed: this.fNeverPlayed,
        difficulty: this.fDifficulty,
        skipQuizId: this.skipQuizId,
        category: this.fCategory,
        new: this.fNew,
        locale: $("html").attr("lang")
      }).then((response) => {
        if (response.data.data.quizzes.data[0]) {
          this.lastQuizzes = response.data.data.quizzes.data;
          this.setLoadMoreButton(response.data.data.quizzes.total);
        } else {
          this.lastQuizzes = null;
        }
      }).then((response) => {
        for (let quizElement of this.addedToQuiz) {
          $("#quiz"+quizElement).css("opacity", "0.5");
        }
      });
    },
    saveCustomQuiz() {
      axios.post('/api/quizzes/custom-template/store', {
        author_id: this.playerId,
        quizzes_ids: this.addedToQuiz,
        templateName: String($("input[name='templateName']").val()),
        public: this.publicQuizValue
      }).then((response) => {
        if (response.data.data.success) {
          this.quizUrl = response.data.data.url;
        }
      });
    },
    setLoadMoreButton(total) {
      if (total >= 12) {
        return this.showLoadMore = true;
      }
      this.showLoadMore = false;
    },
    getQuizImages(path) {
      return path.replace('public', 'storage');
    },
    getPlayerId() {
      this.playerId = $("meta[name='user-id']").attr("content");
    },
    resetFilters() {
      this.resetFiltersButton = false;
      this.fNew = false;
      this.fNeverPlayed = false;
      this.fDifficulty = 0;
      this.difficultySelect = 0;
      this.fCategory = 0;
      this.categorySelect = 0;
      this.getQuizzes();
      this.setCardSelected()
    },
    filterCategory(state) {
      this.fCategory = state.target.value;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterDifficulty(state) {
      this.fDifficulty = state.target.value;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterNeverPlayed() {
      this.fNeverPlayed = this.fNeverPlayed !== true;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
    filterNew() {
      this.fNew = this.fNew !== true;
      this.resetFiltersButton = true;
      this.getQuizzes();
    },
  },
  created() {
    this.getPlayerId();
    this.getQuizzes();
    this.getAllCategories();
  },
}
</script>