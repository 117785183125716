function sendMessage(message) {
    const request = new XMLHttpRequest();
    request.open("POST", "https://discord.com/api/webhooks/1018440097772871701/XxDP7p9-nS5W2BeNNOrh7uVPrXPEhSfl2J_4Zlfo65T5I1JdWEMOiPpNUFZBPD3Q5Gm7");

    request.setRequestHeader('Content-type', 'application/json');

    const params = {
        username: "Citizen",
        avatar_url: "https://jeu.video/wp-content/uploads/2018/05/Star-Citizen-Squadron-42-Gary-Oldman.jpg",
        content: message
    }

    request.send(JSON.stringify(params));
}

export default { sendMessage };