$(document).ready(function () {
    let overlayCards = document.getElementById("homeCards");
    if (overlayCards) {
        $('#homeCards .card__overlay').on('touchstart',function(){
            if ($(this).hasClass("touched")) {
                $(this).css("opacity", "0").removeClass("touched");
            } else {
                $(this).css("opacity", "0.9").addClass("touched");
            }
        });
    }

    let setLocaleSq = document.getElementsByClassName("setLocaleSq");
    if (setLocaleSq) {
        $(".setLocaleSq").on("change", function () {
            axios.post('/api/setLocale', {
                locale: $(this).val(),
            }).then((response) => {
                window.location.href = "/language/" + response.data.data.locale;
            });
        })
    }
})

let arrowChange = document.getElementsByClassName("arrowChange");
if (arrowChange) {
    $(".arrowChange").on("click", function () {
        let element = $(this).find("span");
        if(element.text().search("keyboard_arrow_up") !== -1) {
            element.text("keyboard_arrow_down");
        } else {
            element.text("keyboard_arrow_up")
        }
    });
}

let Page404 = document.getElementById("Container404");
if (Page404) {
    $("body").on("click", "#404EasterEgg", function () {
        $("#Container404").html('<img class="img-fluid" src="/images/eastereggs/pico_jared.jpg">');
    });
}

let goToTop = document.getElementById("goToTop");
if (goToTop) {
    $(window).scroll(function () {
        if($(window).scrollTop() > 20) {
            $("#goToTop").show('fixed-top');
        } else {
            $("#goToTop").hide('fixed-top');
        }
    });
    $('#goToTop').each(function(){
        $(this).click(function(){
            $('html,body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });
    });
}

/**
 * Share quiz link
 * @param quizId
 */
function copyLinkToClipboard(quizId) {
    navigator.clipboard.writeText("https://starquiz.space/quizzes/"+quizId).then(
        function() {
            /* clipboard successfully set */
            window.alert('Success! The text was copied to your clipboard')
        },
        function() {
            /* clipboard write failed */
            window.alert('Opps! Your browser does not support the Clipboard API')
        }
    )
}

/**
 * Set meta on page load
 * @param name
 * @param quizId
 * @param image
 * @param description
 */
function setMeta(name, quizId, image, description) {
    let descriptionOutput = description.replace( /(<([^>]+)>)/ig, '');
    $("title").text(name);
    $("meta[property='og:title']").attr("content", name);
    $("meta[property='og:url']").attr("content", window.location.href);
    $("meta[property='og:image']").attr("content", image);
    $("meta[property='og:description']").attr("content", descriptionOutput);
    $("meta[property='description']").attr("content", descriptionOutput);
}

/**
 *
 * @param string
 * @param limit
 * @param limitChar
 * @param limiter
 * @returns {string}
 */
function sqSubStr(string, limit = 0, limitChar = 20, limiter = "...") {
    if (string.length >= limitChar) {
        return string.substring(limitChar, limit) + limiter;
    }
    return string;

}

export { copyLinkToClipboard, setMeta, sqSubStr }
