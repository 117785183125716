require('./bootstrap');

window.bootstrap = require('bootstrap');

window.Quill = require('./quill');
require('./menu');
require('./dropzone');
require('./custom');
require('./front/quizzes');
require('./discord');
require('./backend/back');
import discord  from "./discord";
import { createApp } from 'vue';
import home from './components/quiz/home';
import quiz from './components/quiz/quiz';
import quizzestemplates from './components/quiz/templates/quizzesTemplates';
import quizzestemplatesshow from './components/quiz/templates/show';
import topsearch from './components/topsearch';
import quizFilters from './components/quiz/filters';
import { i18nVue } from 'laravel-vue-i18n';

const lang = $("html").attr("lang");
const app = createApp({})
    .use(i18nVue, {
        resolve: () => import(`../lang/${lang}.json`)
    });

app.component('home', home);
app.component('quiz', quiz);
app.component('quizzestemplates', quizzestemplates);
app.component('quizzestemplatesshow', quizzestemplatesshow);
app.component('quizFilters', quizFilters);
app.component('topsearch', topsearch);

app.mount('#app');

