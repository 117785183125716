class Quiz {
    getRandomQuizzes()
    {
        $.ajax({
            url:"/api/quizzes/random",
            method:"GET",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success:function(response) {
            },
            error:function(response){
            }
        });
    }
}

export default Quiz

