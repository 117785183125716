import Quill from "quill";
let quillEditors = $("div[id=editor]").length;
let formContent = $("#formContent").length;

if (quillEditors) {
    var quill = new Quill('#editor', {
        theme: 'snow',
    });
    var quill = new Quill('#editorContent2', {
        theme: 'snow',
    });
    var quill = new Quill('#editorContent3', {
        theme: 'snow',
    });
}

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Header from 'quill/formats/header';


Quill.register({
    'modules/toolbar': Toolbar,
    'themes/snow': Snow,
    'formats/bold': Bold,
    'formats/italic': Italic,
    'formats/header': Header
});

export default Quill;

if (quillEditors && formContent) {
    $("#formContent").on("submit", function () {
        let hvalue = $('.ql-editor').html();
        $(this).append("<textarea name='editorContent' style='display:none'>"+hvalue+"</textarea>");
    });
}
