<template>
  <!-- Quiz Result -->
  <div v-if="showResult" id="quizResult" class="d-flex align-items-start mb-4 p-1 border-white-stripped">
    <div class="sq-dark-bg col-12">
      <button class="btn btn-transparent text-white float-right" v-on:click="hideResult">
        <span class="material-icons">close</span>
      </button>
      <span id="result-title" class="text-center w-100 d-block p-4" v-html="resultTitle"></span>
      <div id="result-text" class="text-justify" v-html="resultText"></div>
    </div>
  </div>
  <!-- End quiz result -->
  <div v-bind:id="'quiz'+quizId" class="d-flex align-items-start quiz-item mb-4" v-if="quizId && playerUsername && !showEndQuiz">
    <div class="row w-100 m-0">
      <div id="quizCountRemaining" class="col-lg-12 p-0">
        <p v-text="getCountQuizzesList()">
        </p>
      </div>
      <div class="col-lg-auto col-xl-auto col-md-4 col-sm-12 p-0">
        <img :src="'../../'+quizImages" onerror="this.onerror=null;this.src='/images/default.jpg';" class="img-fluid" :alt="quizName">
        <div class="col text-light p-2">
          <button v-on:click="copyLink" class="btn btn-primary sq-dark-bg mr-2 p-1" data-toggle="tooltip" :title="$t('CopyLink')">
            <span class="material-icons align-top">share</span>
          </button>
          <button v-on:click="signalQuiz" class="btn btn-primary sq-dark-bg mr-2 p-1" data-toggle="tooltip" :title="$t('CopyLink')">
            <span class="material-icons align-top">warning</span>
          </button>
          <span v-if="quizRsiLink" class="btn btn-primary sq-dark-bg mr-2 p-1">
            <a :href="quizRsiLink" target="_blank">
              <span class="material-icons align-top">insert_link</span>
              {{ $t("Source") }}
            </a>
          </span>
          <span v-if="quizMedia" class="btn btn-primary sq-dark-bg mr-2 p-1">
            <a :href="quizMedia" target="_blank">
              <span class="material-icons align-top">insert_link</span>
              {{ $t("MediaLink") }}
            </a>
          </span>
        </div>
      </div>
      <div class="col-lg col-xl col-md-8 col-sm-12 p-0">
        <div class="progress">
          <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <span class="difficultyLevelList text-uppercase" v-text="$t(quizDifficulty.name)"></span>
        <div class="pl-4 pt-4">
          <h3>{{ quizName }}</h3>
          <br>
          <span class="quizQuestion" v-html="quizAnswers.question"></span>
          <div class="row answersOptions mb-4 m-0">
            <div v-for="quizOption in quizOptions" class="col-md-6 col-lg-6 col-sm-12 mb-4">
              <span v-on:click="checkAnswer(quizId, quizOption.id)" class="h-100">{{ quizOption.option }}</span>
            </div>
          </div><!-- answersOptions -->
        </div>
      </div>
    </div>
  </div><!-- quiz -->
  <div v-else-if="!quizId && playerUsername && showEndQuiz">
    <div class="d-flex align-items-center sq-dark-bg">
      <div class="flex-shrink-0 w-25">
        <img src="/images/default.jpg" class="card-img-top" alt="Aucun quiz">
      </div>
      <div class="flex-grow-1 ms-3 text-center">
        <p>{{ $t("QuizCompleted") }}</p>
      </div>
    </div>
  </div>
  <div class="begin" v-else-if="quizId && !playerUsername && !showEndQuiz">
    <div class="row m-0 sq-dark-bg">
      <div class="col-lg-4 col-xl-4 col-md-8 col-sm-12 text-center">
        <h4 class="m-4" v-text="$t('Difficulty')"></h4>
        <p class="mb-4" v-text="$t('CustomQuizTotalQuestion')"></p>
        <span class="success shadow" v-text="$t('Hard') + ' : ' + totalHard"></span>
        <span class="warning shadow" v-text="$t('Medium') + ' : ' + totalMedium"></span>
        <span class="danger shadow" v-text="$t('Easy') + ' : ' + totalEasy"></span>
      </div>
      <div class="col-lg-8 col-xl-8 col-md-8 col-sm-12 p-4">
        <p v-text="$t('DisclaimerCustomQuiz')"></p>
        <input type="text" class="form-control" name="playerUsername" :placeholder="$t('Username')">
        <button v-on:click="setPlayerUsername()" class="btn btn-primary mt-4 float-right" v-text="$t('Save')"></button>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex align-items-center sq-dark-bg">
      <div class="flex-shrink-0 w-25">
        <img src="/images/default.jpg" class="card-img-top" alt="Aucun quiz">
      </div>
      <div class="flex-grow-1 ms-3 text-center">
        <p>{{ $t("NoQuiz") }}</p>
      </div>
    </div>
  </div>
</template>
<script>

import discord from "../../../discord";
import {copyLinkToClipboard, setMeta} from "../../../custom";

export default {
  props: ["quizzesListIds"],
  data() {
    return {
      quizCount: 1,
      quizCountTotal: 1,
      playerUsername: null,
      quizId: {},
      quizName: {},
      quizAnswers: {},
      quizCategory: {},
      quizDifficulty: {},
      quizImages: {},
      quizOptions: {},
      quizRsiLink: "",
      quizMedia: "",
      showResult: null,
      resultTitle: null,
      resultText: null,
      playerId: 0,
      quizzesList: [],
      showEndQuiz: false,
      lastQuiz: false,
      totalEasy:0,
      totalMedium:0,
      totalHard:0
    }
  },
  created() {
    this.setQuizzesList();
    this.getPlayerId();
    this.getQuizzesTemplates();
  },
  methods: {
    setPlayerUsername() {
      this.playerUsername = $("input[name='playerUsername']").val();
    },
    setQuizzesList() {
      // Set the array of quizzes ids
      this.quizzesList = quizzesListIds;
      // Set the total of quizzes in templates
      this.quizCountTotal = this.quizzesList.length;
    },
    getCountQuizzesList() {
      return this.quizCount+"/"+this.quizCountTotal;
    },
    getPlayerId() {
      this.playerId = $("meta[name='user-id']").attr("content");
    },
    hideResult() {
      this.showResult = false;
    },
    copyLink() {
      let urlQuizId = window.location.href.split('/').pop();

      if (!parseInt(urlQuizId)) {
        urlQuizId = 0;
      }
      navigator.clipboard.writeText("https://starquiz.space/custom/templates/"+urlQuizId).then(
          function() {
            /* clipboard successfully set */
            window.alert('Success! The text was copied to your clipboard')
          },
          function() {
            /* clipboard write failed */
            window.alert('Opps! Your browser does not support the Clipboard API')
          }
      )
    },
    signalQuiz() {
      discord.sendMessage("Quiz report: "+this.quizId);
    },
    getQuizzesTemplates () {
      if (this.quizzesList[0]) {
        let urlQuizId = window.location.href.split('/').pop();

        if (!parseInt(urlQuizId)) {
          urlQuizId = 0;
        }

        axios.post('/api/quizzesTemaplates', {
          quizId: this.quizzesList[0],
          playerId: this.playerId,
          locale: $("html").attr("lang"),
          quizTemplateId: urlQuizId
        }).then((response) => {
          console.log(response);
          console.log(this.playerUsername);
          if (response.data.data.quizzes[0]) {
            let items = response.data.data.quizzes[0];
            let difficultyLevel = response.data.data.quizDifficulty;
            this.quizId = items.id;
            this.quizName = items.name;
            this.quizAnswers = items.answers[0];
            this.quizCategory = items.category;
            this.quizDifficulty = items.difficulty[0];
            this.quizImages = items.images[0].path.replace('public', 'storage');
            this.quizOptions = items.quiz_options;
            this.quizRsiLink = items.answers[0].rsi_link;
            this.quizMedia = items.answers[0].media_link;
            this.setMetaData(items.answers[0].question);
            this.setDifficulty(items.difficulty[0].id);
            this.totalEasy = difficultyLevel[0].easy;
            this.totalMedium = difficultyLevel[0].medium;
            this.totalHard = difficultyLevel[0].hard;
            this.showResult = false;
            this.quizzesList.shift();
          } else {
            this.quizId = null;
          }
        });
      } else {
        this.endTemplates();
      }
    },
    endTemplates: function () {
      this.showEndQuiz = true;
    },
    setDifficulty: function (difficultyId) {
      $("div.progress-bar, span.difficultyLevelList").removeClass(["bg-success-stripped", "bg-warning-stripped", "bg-danger-stripped"])
      if (difficultyId === 1) {
        $("div.progress-bar, span.difficultyLevelList").addClass("bg-success-stripped");
      } else if (difficultyId === 2) {
        $("div.progress-bar, span.difficultyLevelList").addClass("bg-warning-stripped");
      } else {
        $("div.progress-bar, span.difficultyLevelList").addClass("bg-danger-stripped");
      }
    },
    checkAnswer: function (quizId, optionId) {
      let ref = this;
      axios.post('/api/quizzesTemaplates/result', {
        quizId: quizId,
        optionId: optionId,
        playerId: this.playerId,
        playerUsername: this.playerUsername,
        nextQuiz: this.quizzesList[0],
        lastQuiz: this.lastQuiz,
        locale: $("html").attr("lang")
      })
          .then((response) => {
            if (response.data.data.quiz[0]) {
              let items = response.data.data.quiz[0];
              ref.quizId = items.id;
              ref.quizName = items.name;
              ref.quizAnswers = items.answers[0];
              ref.quizCategory = items.category;
              ref.quizDifficulty = items.difficulty[0];
              if (items.images[0]) {
                ref.quizImages = items.images[0].path.replace('public', 'storage');
              } else {
                ref.quizImages = "/images/default.jpg";
              }
              ref.quizOptions = items.quiz_options;
              ref.setDifficulty(items.difficulty[0].id);
              ref.showResult = true;
              ref.setQuizResult(response.data);
              this.setMetaData(items.answers[0].question);
              this.quizzesList.shift();
              this.quizCount = this.quizCount+1;
            } else {
              ref.setQuizResult(response.data);
              this.quizId = null;
              this.lastQuiz = true;
              this.showEndQuiz = true;
            }
          });
    },
    setQuizResult(data) {
      let success = data.data.success;
      let items = data.data;
      this.showResult = true;

      if (success === true) {
        this.resultTitle = "Bravo !";
      } else {
        this.resultTitle = "Oups...";
      }
      this.resultText = items.explication;
    },
    setMetaData(question) {
      setMeta(
          this.quizName,
          this.quizId,
          this.quizImages,
          question
      );
    }
  }
}
</script>