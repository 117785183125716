// When document is loaded and ready, run the following functions
document.addEventListener('DOMContentLoaded', function () {

    // Add click event on .modalHowToPlay
    document.querySelector('.modalHowToPlay').addEventListener('click', function () {
        // Show search modal #modalHowToPlay
        const myModal = document.getElementById('modalHowToPlay');
        const modalInstance = new bootstrap.Modal(myModal);

        modalInstance.show();
    });

});